<template>
  <v-dialog v-model="value" persistent max-width="800">
    <v-card color="danger">
      <v-card-title>
        <div class="H4-Secondary-Center">Import Errors</div>
        <div>
          There were errors while trying to import from your excel sheet.
          <br />
          Please correct the errors and try again.
        </div>
      </v-card-title>
      <v-card-text>
        <ul>
          <li class="mt-2" v-for="(error, i) in errors" v-bind:key="i">
            <span v-if="error.sheetName"><strong>Sheet</strong>&nbsp;{{ error.sheetName }}</span>
            <span v-if="error.sheetName && error.row">, </span>
            <span v-if="error.row"><strong>Row</strong>&nbsp;{{ error.row }}</span>
            <br v-if="error.sheetName || error.row" />
            <span style="color: red">{{ error.message }}</span>
          </li>
        </ul>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat v-on:click="close()">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    errors: {
      type: Array,
      required: true,
    },
    value: Boolean,
  },
  methods: {
    close() {
      this.$emit('input', false);
    },
  },
};
</script>
