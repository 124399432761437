import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VDialog,
    {
      attrs: { persistent: "", "max-width": "800" },
      model: {
        value: _vm.value,
        callback: function($$v) {
          _vm.value = $$v
        },
        expression: "value"
      }
    },
    [
      _c(
        VCard,
        { attrs: { color: "danger" } },
        [
          _c(VCardTitle, [
            _c("div", { staticClass: "H4-Secondary-Center" }, [
              _vm._v("Import Errors")
            ]),
            _c("div", [
              _vm._v(
                "\n        There were errors while trying to import from your excel sheet.\n        "
              ),
              _c("br"),
              _vm._v(
                "\n        Please correct the errors and try again.\n      "
              )
            ])
          ]),
          _c(VCardText, [
            _c(
              "ul",
              _vm._l(_vm.errors, function(error, i) {
                return _c("li", { key: i, staticClass: "mt-2" }, [
                  error.sheetName
                    ? _c("span", [
                        _c("strong", [_vm._v("Sheet")]),
                        _vm._v(" " + _vm._s(error.sheetName))
                      ])
                    : _vm._e(),
                  error.sheetName && error.row
                    ? _c("span", [_vm._v(", ")])
                    : _vm._e(),
                  error.row
                    ? _c("span", [
                        _c("strong", [_vm._v("Row")]),
                        _vm._v(" " + _vm._s(error.row))
                      ])
                    : _vm._e(),
                  error.sheetName || error.row ? _c("br") : _vm._e(),
                  _c("span", { staticStyle: { color: "red" } }, [
                    _vm._v(_vm._s(error.message))
                  ])
                ])
              }),
              0
            )
          ]),
          _c(
            VCardActions,
            [
              _c(VSpacer),
              _c(
                VBtn,
                {
                  attrs: { flat: "" },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }